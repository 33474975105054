import 'lazysizes'

// Import our CSS
import "@/css/app.css";

import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()
import VenveoApplication from "./base/VenveoApplication"
import MmenuLight from './components/ui/Mmenu'

const application = new VenveoApplication(
    import.meta.glob("./components/**/*.[j|t]s")
);

application.registerComponent('mmenu-light', '#mobile-menu-nav', MmenuLight)
application.registerComponent('headroomjs', 'header', './components/ui/HeadroomController.ts');
application.registerComponent('glightbox', '.glightbox', './components/ui/Glightbox.ts');
application.registerComponent('card-slider', '[data-card-slider]', './components/ui/sliders/CardSlider/CardSlider.ts');
application.registerComponent('content-image-slider', '[data-content-image-slider]', './components/ui/sliders/ContentImageSlider/ContentImageSlider.ts');
application.registerComponent('vendor-sal', '[data-sal]', './components/ui/SalJS.ts');
application.registerComponent('plyr-video', '[data-plyr-video]', './components/ui/Videos.ts');
application.registerComponent('mid-anchor-nav', '#midStickyNav', './components/ui/MidAnchorNav.ts');

application.init().then(() => {
    console.log('Site is ready.')
})

