'use strict';

import MmenuLight from 'mmenu-light';
import Component from "../../base/Component";

export default class Mmenu extends Component {
    constructor(props: any) {
        super(props);
    }

    init() {
        const menu = new MmenuLight(
            document.querySelector( '#mobile-menu-nav' ),
            "(max-width: 1023px)"
        );

        const navigator = menu.navigation({
            // options
            // Default options ['light', 'dark']
            theme: 'light'
        });

        const drawer = menu.offcanvas({
            // options
            position: 'right'
        });

        document.querySelector( '[data-mobile-menu-trigger]' )?.addEventListener( "click", ( event ) => {
            event.preventDefault();
            if(document.querySelector(".mm-ocd--open")) {
                drawer.close();
                document.querySelector(".ham-trigger")?.classList.remove("open");
            } else {
                document.querySelector(".ham-trigger")?.classList.add("open");
                drawer.open();
            }
        });

        document.querySelector( '.mm-ocd__backdrop' )?.addEventListener( "click", ( event ) => {
            document.querySelector(".ham-trigger")?.classList.remove("open");
        });
    }
}